SNI.Application.addBehavior('braze', context => {

  const userInterface = context.getService('user/user-data');
  let util  = context.getService('utility');
  let debugMode = util.isDebug();
  let debug = context.getService('logger').create('behavior.braze');
  const brazeDestroy = () => {
    if (window.braze) {
      //teardown Braze SDK
      braze.destroy();
      //remove braze altogether to prevent reuse
      window.braze = undefined;
    }
  }

  const messageHandlers = {
    'dalton:logged-in': async (data) => {
      // Only initialize if the user is logged in and ID is present
      if (SNI.Config.braze?.enabled && userInterface.getUserId()) {

        // Do not reinitialize if the user is the same
        if (window.braze && window.braze.getUser()?.getUserId() === userInterface.getUserId()) {
          return;
        }

        const braze = await import('@braze/web-sdk' /* Braze library */);
        if (window.braze) {
          debug.error('Braze is already globally set');
        } else {
          window.braze = braze;
        }
        braze.initialize(SNI.Config.braze.key, {
          baseUrl: SNI.Config.braze.url,
          //enable logging when we are in debug mode
          enableLogging: debugMode
        });

        // show all in-app messages without custom handling
        braze.automaticallyShowInAppMessages();

        // set the current user's external ID before starting a new session
        // you can also call `changeUser` later in the session after the user logs in
        braze.changeUser(userInterface.getUserId());

        braze.getUser().setEmail(data.userEmail);

        // `openSession` should be called last - after `automaticallyShowInAppMessages`
        braze.openSession();

      } else if (window.braze) {
        // If the user is logged out or braze is disabled, remove any lingering sessions
        brazeDestroy();
      }
    },
    'dalton:logged-out': () => {
      brazeDestroy();
    }
  }; //end messageHandlers

  return {
    messages: Object.keys(messageHandlers),
    onmessage(msg, data) {
      messageHandlers[msg](data);
    },
  }
})
